"use client";

import Text from "@/lib/components/Text";
import React, { JSX } from "react";
import CloudImage from "../../CloudImage";
import Link from "next/link";
import { PopularCity, getPopularCityImage, getPopularCityUrl } from "@/lib/types/PopularCity";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";

type ImageGridProps = {
    items: PopularCity[];
};

const ImageGrid = ({ items }: ImageGridProps) => {
    const auth = useAtomValue(authAtom);
    const lang = useLanguage();
    const { t } = useTranslation(lang, "home");

    const gridCols = [2, 3, 4].map((gc) => (gc > items.length ? items.length : gc));

    return (
        <ul
            className={`grid grid-cols-${gridCols[0]} w-full gap-x-4 lg:py-5 lg:gap-y-8 sm:gap-y-4 gap-y-3 sm:grid-cols-${gridCols[1]} sm:gap-x-6 lg:grid-cols-${gridCols[2]}`}
        >
            {items.map((city) => (
                <li className="group" key={`imagegrid-${city.name}`}>
                    <Link
                        onClick={() =>
                            GoogleTagManagerEvents.clickOnMostPopularCities(
                                lang,
                                city.name,
                                auth.isLoggedIn ? auth.userInfo._id : undefined,
                            )
                        }
                        href={getPopularCityUrl(city, lang)}
                        className="relative aspect-square block w-full overflow-hidden rounded-lg bg-gray-100"
                    >
                        <CloudImage
                            src={getPopularCityImage(city)}
                            alt={city.name}
                            fill={true}
                            sizes="270px"
                            className="object-cover w-full h-full transition-transform duration-300 transform group-hover:scale-110 brightness-75"
                        />
                        <div className="absolute flex items-center justify-center flex-col bottom-0 left-0 right-0 py-2 px-4">
                            <Text as="p" className="text-white text-base sm:text-xl font-bold mb-3 sm:mb-2 select-none">
                                {city.name}
                            </Text>
                            <Text as="p" className="text-white text-base font-medium sm:text-xl mb-3.5 select-none">
                                {city.rooms} {t("sections.mostPopular.rooms")}
                            </Text>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default ImageGrid as unknown as (props: ImageGridProps) => JSX.Element;
