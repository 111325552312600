import { CLOUDINARY_FOLDER } from "../constants/cloudinary";
import { Urls, getUrl } from "../constants/urls";

export type PopularCity = {
    name: string;
    rooms: number;
    image: string | null;
    cityUrlSlug?: string | null;
    searchLocation?: string | null;
}

export const getPopularCityUrl = (city: PopularCity, lang: string) => {
    if (city.cityUrlSlug) {
        return getUrl(Urls.city.index, lang, { city: city.cityUrlSlug });
    } else if (city.searchLocation) {
        const locationParams = new URLSearchParams({
            location: city.searchLocation,
        });
        return getUrl(Urls.search.index, lang, null, locationParams.toString());
    }
    return "#";
};

export const getPopularCityImage = (city: PopularCity) => {
    return `${CLOUDINARY_FOLDER}${city.image}`;
};
