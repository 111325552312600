"use client";
import { Urls, getUrl } from "@/lib/constants/urls";
import { LanguageParams } from "@/lib/types/PageProps";
import Link from "next/link";
import Button from "../Button";
import { useTranslation } from "@/lib/i18n/client";
import { GoogleTagManagerEvents } from "@/lib/utils/googleTagEvents";
import { useRegisterNextParams } from "@/lib/hooks/useRegisterNextParams";


const MarketingBannerRegisterButton = ({ lang }: LanguageParams) => {
    const { t } = useTranslation(lang, "home");
    const registerParams = useRegisterNextParams();

    return (
        <Link
            href={getUrl(Urls.register.index, lang, null, registerParams)}
            onClick={() => GoogleTagManagerEvents.gotoRegisterPageClick(lang, "register_now")}
        >
            <Button variant="transparent" className="px-8 font-bold">
                {t("sections.marketing-banner.more-information")}
            </Button>
        </Link>
    );
};

export default MarketingBannerRegisterButton;
