"use client";

import React from "react";
import Slider from "react-slick";
import ContentHeader from "../Headers/ContentHeader";
import "../../../assets/css/slider.css";
import { Cloudinary } from "@/lib/constants/cloudinary";
import CloudImage from "@/lib/components/CloudImage";
import { SCREEN_BREAKPOINTS } from "@/lib/constants/tailwind";
import { useTranslation } from "@/lib/i18n/client";
import HomeContainer from "./HomeContainer";
import { LanguageParams } from "@/lib/types/PageProps";

const Partners = ({ lang }: Readonly<LanguageParams>) => {
    const { t } = useTranslation(lang, "home");
    const settings = {
        dots: true,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        speed: 700,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: SCREEN_BREAKPOINTS.lg,
                settings: {
                    slidesToShow: 4,
                    initialSlide: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: SCREEN_BREAKPOINTS.md,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: SCREEN_BREAKPOINTS.sm,
                settings: {
                    slidesToShow: 2,
                    initialSlide: 2,
                    infinite: true,
                    dots: true,
                },
            },
        ],
    };

    const images = Cloudinary.home.partners;

    return (
        <HomeContainer>
            <div className="flex flex-col gap-2 slick-partners">
                <ContentHeader
                    title={t("sections.partners.title")}
                    description={t("sections.partners.subtitle") || undefined}
                />
                <Slider {...settings}>
                    {images.map((img) => {
                        return (
                            <div className="rounded-md w-48 lg:w-64 h-32 lg:h-40" key={img.url}>
                                <CloudImage
                                    src={img.url}
                                    alt={img.title}
                                    width={256}
                                    height={156}
                                    className={"rounded-md"}
                                />
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </HomeContainer>
    );
};

export default Partners;
